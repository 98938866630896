import React from 'react';
import styled from '@emotion/styled';
import tw from "twin.macro";

import Image from '../../components/Image/Image';

import mq from '@styles/media';
import { IArticle, IAuthor } from '@types';
import Headings from '../../components/Headings';

// import ArticleAuthors from './Article.Authors';

interface ArticleHeroProps {
    article: IArticle;
    authors: IAuthor[];
}

const ArticleHero: React.FC<ArticleHeroProps> = ({ article, authors }) => {
    const hasCoAUthors = authors.length > 1;
    const hasHeroImage =
        Object.keys(article.hero.full).length !== 0 &&
        article.hero.full.constructor === Object;

    const date = new Date(article.dateForSEO);
    const formattedDate = date.toLocaleString('hu-HU',{ dateStyle: "full" });
    return (
    <Hero>
        <Header>
            <HeroHeading>{article.title}</HeroHeading>
            <HeroSubtitle hasCoAUthors={hasCoAUthors}>
                {/* <ArticleAuthors authors={authors} /> */}
                <ArticleMeta hasCoAUthors={hasCoAUthors}>
                    {formattedDate} · {Math.ceil(article.timeToRead.minutes)} perc olvasás
                </ArticleMeta>
            </HeroSubtitle>
        </Header>
        <HeroImage id="ArticleImage__Hero">
            <Image src={hasHeroImage ? article.hero.full : `https://dummyimage.com/640x360/ddd/aaa"`} alt={article.title} />
        </HeroImage>
    </Hero>
    );
};

export default ArticleHero;

const Hero = styled.div`
    ${p => mq.to_tablet`
        &::before {
            content: "";
            width: 100%;
            height: 20px;
            background: ${p.theme.colors.primary};
            position: absolute;
            left: 0;
            top: 0;
            transition: ${p.theme.colorModeTransition};
        }

        &::after {
            content: "";
            width: 100%;
            height: 10px;
            background: ${p.theme.colors.background};
            position: absolute;
            left: 0;
            top: 10px;
            border-top-left-radius: 25px;
            border-top-right-radius: 25px;
            transition: ${p.theme.colorModeTransition};
        }
    `}
`;

const ArticleMeta = styled.div<{ hasCoAUthors: boolean }>`
    margin-left: ${p => (p.hasCoAUthors ? '10px' : '0')};

    ${mq.to_tablet`
        margin-left: 0;
    `}
`;

const Header = styled.div`
    ${tw`relative z-10 mx-auto py-24 pb-16 px-8 lg:py-32 lg:pb-24 w-full max-w-lg md:max-w-xl lg:max-w-2xl`}
`;

const HeroHeading = styled(Headings.h1)`
    ${tw`mb-4 font-bold leading-normal`};
`;

const HeroSubtitle = styled.div<{ hasCoAUthors: boolean }>`
    ${tw`relative flex text-base md:text-lg flex-col`};
    color: ${p => p.theme.colors.grey};

    ${p => mq.to_tablet`
        ${p.hasCoAUthors &&
        `
            &::before {
                content: '';
                position: absolute;
                left: -20px;
                right: -20px;
                top: -10px;
                bottom: -10px;
                border: 1px solid ${p.theme.colors.horizontalRule};
                opacity: 0.5;
                border-radius: 5px;
            }
        `}


        strong {
            ${tw`block font-semibold mb-3`};
        }
    `}
`;

const HeroImage = styled.div`
    ${tw`relative z-1 w-full h-56 md:h-full md:max-w-full lg:max-w-5xl overflow-hidden mx-auto my-0 `};
    & > div {
        ${tw`h-56 md:h-full`};
    }
    
    box-shadow: 0 30px 60px -10px rgba(0, 0, 0, 0.2),
        0 18px 36px -18px rgba(0, 0, 0, 0.22);

    ${mq.to_tablet`
        width: calc(100vw - 40px);
    `}
`;
