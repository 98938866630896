import styled from "@emotion/styled";
import tw from "twin.macro";

const Paragraph = styled.p`
    ${tw`leading-relaxed text-base mx-auto mb-6 w-full max-w-3xl text-gray-800 md:mb-4 lg:max-w-2xl md:max-w-xl sm:max-w-lg`};
    font-family: ${p => p.theme.fonts.sansSerif};
    transition: ${p => p.theme.colorModeTransition};
    b {
        ${tw`font-bold`};
    }
`;

export default Paragraph;
