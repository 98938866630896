import React, { useRef, useState, useEffect } from "react";
import styled from "@emotion/styled";
import throttle from "lodash/throttle";
import { graphql, useStaticQuery } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/SEO/SEO";
import RichText from "../components/RichText";
import Progress from "../components/Progress";
import Section from "../components/Section";
import Wrapper from "../components/Wrapper";
// import Subscription from "@components/Subscription";

import mq from "@styles/media";
import { debounce } from "@utils";

import ArticleAside from "../sections/article/Article.Aside";
import ArticleHero from "../sections/article/Article.Hero";
import ArticlesNext from "../sections/article/Article.Next";
// import ArticleSEO from "../sections/article/Article.SEO";
// import ArticleShare from "../sections/article/Article.Share";

import { Template } from "@types";
import useSiteMetadata from "../hooks/useSiteMetadata";

const Article: Template = ({ pageContext, location }) => {
  const contentSectionRef = useRef<HTMLElement>(null);

  const [hasCalculated, setHasCalculated] = useState<boolean>(false);
  const [contentHeight, setContentHeight] = useState<number>(0);

  const results = useSiteMetadata();
  const name = results.name;

  const { article, authors, mailchimp, next } = pageContext;

  useEffect(() => {
    const calculateBodySize = throttle(() => {
      const contentSection = contentSectionRef.current;

      if (!contentSection) return;

      /**
       * If we haven't checked the content's height before,
       * we want to add listeners to the content area's
       * imagery to recheck when it's loaded
       */
      if (!hasCalculated) {
        const debouncedCalculation = debounce(calculateBodySize);
        const $imgs = contentSection.querySelectorAll("img");

        $imgs.forEach($img => {
          // If the image hasn't finished loading then add a listener
          if (!$img.complete) $img.onload = debouncedCalculation;
        });

        // Prevent rerun of the listener attachment
        setHasCalculated(true);
      }

      // Set the height and offset of the content area
      setContentHeight(contentSection.getBoundingClientRect().height);
    }, 20);

    calculateBodySize();
    window.addEventListener("resize", calculateBodySize);

    return () => window.removeEventListener("resize", calculateBodySize);
  }, []);

  const url = (
    article.hero.seo.src.includes('https:') ||
    article.hero.seo.src.includes('http:')
    ) ? article.hero.seo.src : 'https:' + article.hero.seo.src;

  const postData = {
    language: 'hu',
    url: location.href,
    name: article.title,
    alternateName: article.title,
    title: article.title,
    description: article.excerpt,
    date: article.dateForSEO,
    modifyDate: article.dateForSEO,
    image: url,
    author: 'iFaktor Agency',
    wordcount: article.timeToRead.words,
    timeToRead: Math.ceil(article.timeToRead.minutes).toString()
  };

  return (
    <Layout>
        {article.secret ?
        <SEO
        title={article.title}
        description={article.excerpt}
        noIndex={true}
        slug={location.pathname}
        />
        :
        <SEO
        title={article.title}
        description={article.excerpt}
        slug={location.pathname}
        postData={postData}
        isBlogPost
        />
      }
        <ArticleHero article={article} authors={authors} />
        <ArticleAside contentHeight={contentHeight}>
            <Progress contentHeight={contentHeight} />
        </ArticleAside>
        <Wrapper>
            <ArticleBody ref={contentSectionRef}>
                <RichText content={article.body} />
            </ArticleBody>
        </Wrapper>
      {/* {mailchimp && article.subscription && <Subscription />} */}
      {next.length > 0 && (
        <NextArticle>
            <Wrapper>
                <FooterNext> További cikkek a {name}-től</FooterNext>
                <ArticlesNext articles={next} />
            </Wrapper>
        </NextArticle>
      )}
    </Layout>
  );
};

export default Article;

const ArticleBody = styled.article`
  position: relative;
  padding: 160px 0 35px;
  padding-left: 68px;
  transition: background 0.2s linear;

  ${mq.to_desktop`
    padding-left: 53px;
  `}
  
  ${mq.to_tablet_ipad`
    padding: 70px 0 80px;
  `}

  ${mq.to_tablet`
    padding: 60px 0;
  `}
`;

const NextArticle = styled(Section)`
  display: block;
`;

const FooterNext = styled.h3`
  position: relative;
  opacity: 0.25;
  margin-bottom: 100px;
  font-weight: 400;
  color: ${p => p.theme.colors.primary};

  ${mq.to_tablet_ipad`
    margin-bottom: 60px;
  `}

  &::after {
    content: '';
    position: absolute;
    background: ${p => p.theme.colors.grey};
    width: ${(850 / 1140) * 100}%;
    height: 1px;
    right: 0;
    top: 11px;

    ${mq.to_tablet_ipad`
      width: ${(600 / 1140) * 100}%;
    `}

    ${mq.to_tablet`
      width: ${(400 / 1140) * 100}%;
    `}

    ${mq.to_mobile_i6p`
      width: 90px
    `}
  }
`;
