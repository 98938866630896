import styled from "@emotion/styled";
import { css } from "@emotion/core";
import tw from "twin.macro";

/**
 * Example:
 * <Headings.h1>Lorem Ipsum</Headings.h1>
 */

const commonStyles = p => css`
    ${tw`font-bold`};
    color: ${p.theme.colors.primary};
    font-family: ${p.theme.fonts.serif};
`;

const h1 = styled.h1`
    ${tw`text-2xl md:text-3xl lg:text-4xl xl:text-5xl leading-snug lg:leading-tight`};
    ${commonStyles};
`;

const h2 = styled.h2`
    ${tw`text-xl md:text-2xl lg:text-3xl xl:text-4xl leading-tight lg:leading-snug`};
    ${commonStyles};
`;

const h3 = styled.h3`
    ${tw`text-lg md:text-xl lg:text-2xl xl:text-3xl leading-loose`};
    ${commonStyles};
`;

const h4 = styled.h4`
    ${tw`text-base md:text-lg lg:text-xl xl:text-2xl leading-loose`};
    ${commonStyles};
`;

const h5 = styled.h5`
    ${tw`text-base md:text-lg xl:text-xl leading-loose`};
    ${commonStyles};
`;

const h6 = styled.h6`
    ${tw`text-base leading-loose`};
    ${commonStyles};
`;

export default {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
};
