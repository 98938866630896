import styled from "@emotion/styled";
import tw from "twin.macro";

const HorizontalRule = styled.hr<{isDark?: boolean}>`
    ${tw`relative w-full my-12 mx-auto border-none h-4 bg-repeat-x bg-center`};
    background-image: url("${p =>
        p.isDark
            ? "data:image/svg+xml,%3Csvg width='10' height='15' viewBox='0 0 10 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.432617' y='13.8564' width='16' height='1' transform='rotate(-60 0.432617 13.8564)' fill='%2350525B'/%3E%3C/svg%3E%0A"
            : "data:image/svg+xml,%3Csvg width='10' height='15' viewBox='0 0 10 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.567383' y='14.1777' width='16' height='1' transform='rotate(-60 0.567383 14.1777)' fill='%232D2E33'/%3E%3C/svg%3E"}");
  `;

export default HorizontalRule;
