import styled from "@emotion/styled";
import tw from "twin.macro";

const Blockquote = styled.blockquote`
    transition: ${p => p.theme.colorModeTransition};
    ${tw`mt-4 mb-8 mx-auto sm:ml-0 sm:pl-4 lg:pl-6 xl:pl-24 text-gray-900 italic`};

    & > p {
        ${tw`leading-normal font-bold max-w-full w-full m-0 mx-auto text-3xl sm:text-4xl`};
    }
`;

export default Blockquote;
