import React, { ReactNode } from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import tw from "twin.macro";
import { BLOCKS, MARKS, INLINES, Inline } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer";
import readingTime from "reading-time";

import Anchor from "../Anchor";
import Blockquote from "../Blockquote";
import Headings from "../Headings";
import HorizontalRule from "../HorizontalRule";
import Lists from "../Lists";
import Paragraph from "../Paragraph";
import Image from "../Image/Image";
import Link from "../Link";

interface RichTextProps {
    content: React.ReactNode;
};

const Bold = styled.span`
    ${tw`font-bold`};
`;

const Italic = styled.span`
    ${tw`italic`};
`;

const Underline = styled.span`
    ${tw`underline`};
`;

const defaultInline = (type: string, node: Inline): ReactNode => {
    return null;
};

const RichText: React.FC<{}> = ({ content, children, ...props }) => {
    const options = {
        renderText: text => {
            return text.split('\n').reduce((children, textSegment, index) => {
              return [...children, index > 0 && <br key={index} />, textSegment];
            }, []);
        },
        renderMark: {
            [MARKS.BOLD]: text => <Bold>{text}</Bold>,
            [MARKS.ITALIC]: text => <Italic>{text}</Italic>,
            [MARKS.UNDERLINE]: text => <Underline>{text}</Underline>,
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => <Paragraph>{children}</Paragraph>,
            [BLOCKS.HEADING_1]: (node, children) => <Headings.h2>{children}</Headings.h2>,
            [BLOCKS.HEADING_2]: (node, children) => <Headings.h2>{children}</Headings.h2>,
            [BLOCKS.HEADING_3]: (node, children) => <Headings.h3>{children}</Headings.h3>,
            [BLOCKS.HEADING_4]: (node, children) => <Headings.h4>{children}</Headings.h4>,
            [BLOCKS.HEADING_5]: (node, children) => <Headings.h5>{children}</Headings.h5>,
            [BLOCKS.HEADING_6]: (node, children) => <Headings.h6>{children}</Headings.h6>,
            [BLOCKS.UL_LIST]: (node, children) => <Lists.ul>{children}</Lists.ul>,
            [BLOCKS.OL_LIST]: (node, children) => <Lists.ol>{children}</Lists.ol>,
            [BLOCKS.QUOTE]: (node, children) => <Blockquote>{children}</Blockquote>,
            [BLOCKS.HR]: () => <HorizontalRule />,
            [BLOCKS.EMBEDDED_ENTRY]: node => {
                if (node.data.target.__typename !== "ContentfulPost") return null;
                const image = node.data.target.hero;
                const article = node.data.target;
                const date = new Date(article.dateForSEO);
                const formattedDate = date.toLocaleString('hu-HU',{ dateStyle: "long" });

                const jsonData = JSON.parse(article.body.raw);
                const text = documentToPlainTextString(jsonData);
                const timeToRead = readingTime(text);
                return (
                <Link to={`/blog/${node.data.target.slug}`} title={article.title}>
                    <EmbeddedCard>
                        <EmbeddedImageContainer>
                            <Image src={image.small} alt={image.title} />
                        </EmbeddedImageContainer>
                        <EmbeddedContentContainer>
                            <Title>{article.title}</Title>
                            <Excerpt>{article.excerpt}</Excerpt>
                            <Details>
                                <Detail>{formattedDate}</Detail>
                                <Detail>{Math.ceil(timeToRead.minutes)} perc olvasás</Detail>
                            </Details>
                        </EmbeddedContentContainer>
                    </EmbeddedCard>
                </Link>
                    
                );
            },
            [BLOCKS.EMBEDDED_ASSET]: node => {
                if (node.data.target.__typename !== "ContentfulAsset") return null;
                const image = node.data.target;
                return (
                    <ImageContainer>
                        <Image src={image.full} alt={image.title} />
                    </ImageContainer>
                )
            },
            [INLINES.ASSET_HYPERLINK]: node => defaultInline(INLINES.ASSET_HYPERLINK, node as Inline),
            [INLINES.ENTRY_HYPERLINK]: (node, children) => {
                if (node.data.target.__typename !== "ContentfulPost") return null;
                return <InsideLink to={`/blog/${node.data.target.slug}`} title={node.data.target.title}>{children}</InsideLink>;
            },
            [INLINES.EMBEDDED_ENTRY]: node => {
                if (node.data.target.__typename !== "ContentfulPost") return null;
                return <InsideLink to={`/blog/${node.data.target.slug}`} title={node.data.target.title}>{node.data.target.title}</InsideLink>;
            },
            [INLINES.HYPERLINK]: (node, children) => {
                return node.data.uri.includes("ifaktoragency.hu") ?
                    <InsideLink to={node.data.uri.split("ifaktoragency.hu")[1] == `` ? `/` : node.data.uri.split("ifaktoragency.hu")[1]}>{children}</InsideLink> :
                    <Anchor underline href={node.data.uri}>{children}</Anchor>;
            },
        },
  }

  return (
    <RichTextBody {...props}>
        {renderRichText(content, options)}
        {children}
    </RichTextBody>
  );
};

export default RichText;

const InsideLink = styled(Link)`
    ${tw`font-semibold`};
    transition: ${p => p.theme.transitionValue};
    color: ${p => p.theme.colors.accent};

    &:visited {
        opacity: 0.85;
    }

    &:hover,
    &:focus {
        text-decoration: underline;
        color: ${p => p.theme.colors.accentHover};
    }
`

const ARTICLE_WIDTH = css`
    ${tw`w-full max-w-lg md:max-w-xl lg:max-w-2xl`}
`;

const HeadingsCSS = css`
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        ${tw`m-0 mx-auto`};
        ${ARTICLE_WIDTH};
    }

    h1,
    h1 *,
    h2,
    h2 * {
        ${tw`mt-6 mb-4 mx-auto`};
    }

    h3,
    h3 * {
        ${tw`mt-4 mb-2 mx-auto`};
    }
`;

const GeneralCSS = css`
    ul, ol {
        ${ARTICLE_WIDTH};
    }
`;

const BlockquoteCSS = css`
    blockquote {
        ${tw`max-w-sm sm:max-w-3xl`};
    }
`;

const HorizontalRuleCSS = css`
    hr {
        ${ARTICLE_WIDTH};
    }
`;

const ImageCSS = css`
    .gatsby-resp-image-background-image {
        display: none !important;
    }

    img {
        ${tw`inline-block relative max-w-full h-auto z-0 mt-4 mb-8 mx-auto rounded-md`};
    }

    div.Image__Small {
        ${tw`inline-block relative max-w-full h-auto z-0 mt-4 mb-8 mx-auto rounded-md w-full max-w-md md:max-w-lg lg:max-w-xl`};
        // max-width: 680px;
    }

    .Image__Container {
        ${tw`text-center`};
    }

    img.Image__With-Shadow {
        box-shadow: 0px 15px 60px rgba(0, 0, 0, 0.15);
    }

    div.Image__Medium {
        ${tw`relative mt-4 mb-8 mx-auto w-full max-w-lg md:max-w-xl lg:max-w-2xl`};
        img {
            ${tw`rounded-none lg:rounded-md`};
        }
    }

    div.Image__Large {
        ${tw`relative mt-4 mb-8 md:mt-6 md:mb-10 mx-auto w-full rounded-none lg:rounded-md max-w-xl md:max-w-2xl lg:max-w-3xl`};
        pointer-events: none;

        img {
            ${tw`rounded-none`};
        }
    }
`;

const ImageContainer = styled.div`
    ${ARTICLE_WIDTH};
    ${tw`mx-auto my-8`};
    & > div {
        ${tw`shadow-xl rounded-md`};
    }
`;

const EmbeddedCard = styled.div`
    ${ARTICLE_WIDTH};
    transition: ${p => p.theme.transitionValue};
    ${tw`md:h-48 bg-white mx-auto my-4 shadow-xl hover:shadow-2xl flex flex-col md:flex-row items-center justify-between overflow-hidden rounded-md`};
`;

const EmbeddedImageContainer = styled.div`
    ${tw`w-full md:w-1/3 h-auto md:h-full bg-gray-200 rounded-none`};
    & > div, & img {
        ${tw`w-full h-full rounded-none m-0`};
        margin: 0px !important;
        border-radius: 0 !important;
    }
`;

const EmbeddedContentContainer = styled.div`
    ${tw`w-full md:w-2/3 p-4 md:h-full flex flex-col justify-evenly`};
`;

const Title = styled.h3`
    ${tw`text-lg font-semibold leading-none tracking-normal uppercase normal-case md:text-xl`};
    margin-top: 0px !important;
`;

const limitToTwoLines = css`
    text-overflow: ellipsis;
    overflow-wrap: normal;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    white-space: normal;
    overflow: hidden;
    ${tw`line-clamp-2 md:line-clamp-3`};
`;

const Excerpt = styled.p`
    ${limitToTwoLines};
    ${tw`text-gray-600 text-base mt-3`};
`;

const Details = styled.div`
    ${tw`flex flex-col items-center sm:flex-row sm:justify-between mt-4 items-start`};
`;

const Detail = styled.span`
    ${tw`text-gray-500 text-base font-semibold`};
`;

/**
 * RichTextBody
 * Here we're applying "global" selectors to make sure we maintain an article
 * body type feel. We're also applying all the Prism selecotors and styles within
 * the RichTextBody.
 */
const RichTextBody = styled.div`
    position: relative;
    z-index: 10;
    display: flex;
    justify-content: center;
    flex-direction: column;

    ${HeadingsCSS};
    ${ImageCSS};
    ${GeneralCSS};
    ${BlockquoteCSS};
    ${HorizontalRuleCSS};
`;
