import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import tw from "twin.macro";

import Image from "../../components/Image/Image";
import Link from "../../components/Link";

import mq from "@styles/media";

import { IArticle } from "@types";

interface ArticlesNextProps {
  articles: IArticle[]
}

/**
 * Sits at the bottom of our Article page. Shows the next 2 on to_desktop and the
 * next 1 on mobile!
 *
 *  [..............], [.........]
 *  [.....LONG.....], [..SHORT..]
 *  [..............], [.........]
 *
 * This does NOT use Articles.List because there's a special case of only have 1 article
 * as the next one suggested article, which requires special styling we didn't want to
 * mix into the generic list component.
 */
const ArticlesNext: React.FC<ArticlesNextProps> = ({ articles }) => {
  if (!articles) return null;
  const numberOfArticles = articles.length;
  return (
    <Grid numberOfArticles={numberOfArticles}>
      <GridItem article={articles[0]} />
      <GridItem article={articles[1]} narrow />
    </Grid>
  );
};

export default ArticlesNext;

interface GridItemProps {
  article: IArticle;
  narrow?: boolean;
}

const GridItem: React.FC<GridItemProps> = ({ article, narrow }) => {
  if (!article) return null;

  const hasOverflow = narrow && article.title.length > 35;
  const imageSource = narrow ? article.hero.narrow : article.hero.regular;
  const date = new Date(article.dateForSEO);
  const formattedDate = date.toLocaleString('hu-HU',{ dateStyle: "long" });

  return (
    <ArticleLink
      to={`/blog/${article.slug}`}
      data-a11y="false"
      narrow={narrow ? "true" : "false"}
    >
      <Item>
        <ImageContainer>
          <Image src={imageSource} />
        </ImageContainer>
        {/* <Title dark hasOverflow={hasOverflow}>
          {article.title}
        </Title>
        <Excerpt hasOverflow={hasOverflow}>{article.excerpt}</Excerpt>
        <MetaData>
          {article.date} · {article.timeToRead} perc olvasás
        </MetaData>{" "} */}
        <Content>
            <Title>{article.title}</Title>
            <Excerpt>{article.excerpt}</Excerpt>
            <Details>
                <Detail>{formattedDate}</Detail>
                <Detail>{Math.ceil(article.timeToRead.minutes)} perc olvasás</Detail>
            </Details>
        </Content>
      </Item>
    </ArticleLink>
  );
};

const wide = "1fr";
const narrow = "457px";

const limitToTwoLines = css`
  text-overflow: ellipsis;
  overflow-wrap: normal;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  white-space: normal;
  overflow: hidden;

  ${mq.to_tablet`
    -webkit-line-clamp: 3;
  `}
`;
const Grid = styled.div<{ numberOfArticles: number }>`
  position: relative;
  display: grid;
  ${p => {
    if (p.numberOfArticles === 1) {
      return `
      grid-template-columns: 1fr;
      grid-template-rows: 1
    `;
    } else {
      return `
      grid-template-columns: ${wide} ${narrow};
      grid-template-rows: 2;
      `;
    }
  }}
  column-gap: 30px;
  margin: 0 auto;
  max-width: ${p => (p.numberOfArticles === 1 ? "680px" : "100%")};

  ${mq.to_desktop`
    grid-template-columns: 1fr 1fr;
  `}

  ${mq.to_tablet_ipad`
    grid-template-columns: 1fr;
  `}
`;

const ImageContainer = styled.div`
    ${tw`relative shadow-2xl rounded-md overflow-hidden mb-8`};
    height: 280px;
    //   box-shadow: 0 30px 60px -10px rgba(0, 0, 0, ${p => (p.narrow ? 0.22 : 0.3)}),
    //     0 18px 36px -18px rgba(0, 0, 0, ${p => (p.narrow ? 0.25 : 0.33)});
    transition: transform 0.3s var(--ease-out-quad),
    box-shadow 0.3s var(--ease-out-quad);

    & > div {
        height: 100%;
    }

    ${mq.to_tablet_ipad`
        height: 220px;
        margin-bottom: 35px;
    `}

    ${mq.to_tablet`
        height: 200px;
        margin-bottom: 0;
        box-shadow: none;
        overflow: hidden;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;
    `}
`;

const Item = styled.div`
    position: relative;
`;

const Content = styled.div`
    ${tw`w-full pb-4 mx-auto my-0 md:pb-4 z-2`};
`;

const Title = styled.h3`
    ${tw`mt-3 text-lg font-semibold leading-none tracking-normal uppercase normal-case md:text-xl`};
`;

const Excerpt = styled.p`
    ${limitToTwoLines};
    ${tw`text-gray-600 text-base mt-3`};
`;

const Details = styled.div`
    ${tw`flex flex-col items-center sm:flex-row sm:justify-between mt-4 items-start`};
`;

const Detail = styled.span`
    ${tw`text-gray-500 text-base font-semibold`};
`;

const ArticleLink = styled(Link)<{ narrow: string }>`
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  transition: transform 0.33s var(--ease-out-quart);
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

  &:hover ${ImageContainer} {
    transform: translateY(-1px);
    box-shadow: 0 50px 80px -20px rgba(0, 0, 0, 0.27),
      0 30px 50px -30px rgba(0, 0, 0, 0.3);
  }

  &:hover h2,
  &:focus h2 {
    color: ${p => p.theme.colors.accent};
  }

  &[data-a11y="true"]:focus::after {
    content: "";
    position: absolute;
    left: -2%;
    top: -2%;
    width: 104%;
    height: 104%;
    border: 3px solid ${p => p.theme.colors.accent};
    background: rgba(255, 255, 255, 0.01);
  }

  ${p => p.narrow === "true" && mq.to_tablet_ipad`display: none;`}

  ${mq.to_tablet`
    &:hover ${ImageContainer} {
      transform: none;
      box-shadow: initial;
    }

    &:active {
      transform: scale(0.97) translateY(3px);
    }
  `}
`;
